import React, { useEffect, useState } from "react";

//mui components
import styled from "@emotion/styled";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Loader from "components/Common/Loader";
import { Box } from "@mui/material";
import { ReactComponent as RedTriangle } from "assets/red-triangle.svg";
import { ReactComponent as GreenTriangle } from "assets/green-triangle.svg";
const Container = styled("div")(({ theme }) => ({
  "& .table_header": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1rem 0",
    "& .header": {
      color: "#4F4F4F",
      fontWeight: "600",
      padding: "0.5rem 1rem",
      borderRadius: "6px",
      background: "#f2f2f2",
      boxShadow: "0px 4px 6px rgba(105, 105, 105, 0.07)",
    }
  }
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  height: "80px",
  maxHeight: "80px",
  "& .header_row": {
    background: "#084B83",
    "& .header": {
      color: "#FFFFFF",
      fontSize: "15px",
      fontWeight: "700",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      [theme.breakpoints.down("xl")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    }
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: "1rem",
  paddingRight: "1rem",
  "& .table_data_style": {
    color: "#303030",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
    borderRadius: "20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
    "& .link_style": {
      color: "#084B83",
      fontWeight: "600",
      "&:hover": {
        color: "#3EBFAB",
      }
    }
  },
  "& .table_data_num": {
    color: "#303030",
    fontWeight: "500",
    fontSize: "14px",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
    borderRadius: "20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
    "& .numbers_style": {
      padding: "0.4rem 1.4rem",
      borderRadius: "14px",
    }
  }

}));

const columnHeader = [
  {
    label: "Rank",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Page Name",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Party",
    align: "left",
    type: "top_pages",
    report: "all",
  },
  {
    label: "Total Score",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Posts",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Shares",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "No. of Posts in Top10",
    align: "center",
    type: "",
    report: "specific",
  },
  {
    label: "Max Shares of a Post",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Median Shares of Post",
    align: "center",
    type: "",
    report: "all",
  },
  {
    id: "verification_status",
    label: "Verification",
    align: "center",
    sortable: true,
  },
  {
    id: "followers_count",
    label: "Followers",
    align: "center",
    sortable: true,
  },
];
const instaColumnHeaderDashboard = [
  {
    label: "Rank",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Account Name",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Party",
    align: "left",
    type: "top_pages",
    report: "all",
  },
  {
    label: "Total Score",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Posts",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Likes",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "No. of Posts in Top10",
    align: "center",
    type: "",
    report: "specific",
  },
  {
    label: "Max Likes of a Post",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Median Likes of Post",
    align: "center",
    type: "",
    report: "all",
  },
  // {
  //   id: "verification_status",
  //   label: "Verification",
  //   align: "center",
  //   sortable: true,
  // },
  // {
  //   id: "followers_count",
  //   label: "Followers",
  //   align: "center",
  //   sortable: true,
  // },
];

const instaColumnHeader =[
  {
    label: "Rank",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Account Name",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Party",
    align: "left",
    type: "top_pages",
    report: "all",
  },
  {
    label: "Total Score",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Posts",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Likes",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "No. of Posts in Top10",
    align: "center",
    type: "",
    report: "specific",
  },
  {
    label: "Max Likes of a Post",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Median Likes of Post",
    align: "center",
    type: "",
    report: "all",
  },
]
const tailwidnHeader=[
  {
    label: "Rank",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Page Name",
    align: "left",
    type: "",
    report: "all",
  },
  {
    label: "Party",
    align: "left",
    type: "top_pages",
    report: "all",
  },
  {
    label: "Total Score",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Posts",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Shares",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "Total Engagements",
    align: "center",
    type: "",
    report: "all",
  },
  {
    label: "No. of Posts in Top10",
    align: "center",
    type: "",
    report: "specific",
  },
  {
    label: "Max Shares of a Post",
    align: "center",
    type: "",
    report: "all",
  },
 
  {
    id: "verification_status",
    label: "Verification",
    align: "center",
    sortable: true,
  },
  {
    id: "followers_count",
    label: "Followers",
    align: "center",
    sortable: true,
  },
];

const SimpleTable = ({ rows, start_date, end_date, partyRequired, heading, reportType,platform,isDashboard,isTailwind}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [val, setValue] = useState([]);
  const [formattingData, setFormattingData] = useState([]);
  const getColumnHeaders = () => {
    let headers = (platform === 'instagram'&& !isDashboard) ? [...instaColumnHeader] :(platform==="instagram" && isDashboard)?[...instaColumnHeaderDashboard]: [...columnHeader];
    
    if (isTailwind) {
      // Find the index of "Median Shares of Post" and "No. of Posts in Top10" columns
      const medianSharesIndex = headers.findIndex(col => col.label === "Median Shares of Post");
      const postsInTopIndex = headers.findIndex(col => col.label === "No. of Posts in Top10");
      
      // Change "Median Shares of Post" to "Total Engagements"
      if (medianSharesIndex !== -1) {
        headers[medianSharesIndex].label = "Total Engagements";
      }
      
      // Swap their positions
      if (medianSharesIndex !== -1 && postsInTopIndex !== -1) {
        [headers[medianSharesIndex], headers[postsInTopIndex]] = [headers[postsInTopIndex], headers[medianSharesIndex]];
      }
    }
    
    return headers;
  };
  const randomColour = (field, value) => {
    if(value === 0) {
      return "#FFFFFF";
    }
    if (formattingData.length > 0) {
      const data = [...formattingData].sort((firstVal, secondVal) => {
        return secondVal[`${field}`] - firstVal[`${field}`]
      });
      let index_new = data.findIndex(x => x[`${field}`] === value);
      if (index_new >= 7) {
        return "#FFFFFF";
      }
      // const colors = ["#5FF1F1", "#8EFBFB", "#B0F9F9", "#DAFFFF", "#ECFFFF", "#5cd65c"];
      const colors = ["#70db70", "#85e085", "#99e699", "#adebad", "#c2f0c2", "#d6f5d6", "#ebfaeb"];
      var random_color = colors[index_new];
      return random_color;
    }
    return "#FFFFFF";
  };

  useEffect(() => {
    setIsLoading(true)
    if (rows.length > 0) {
      setValue(rows);
      setFormattingData([...rows]);
      setIsLoading(false)
    } else {
      setValue([]);
      setFormattingData([]);
    }
  }, [rows]);
  return (
    <Container>
      {partyRequired !== "required" ?
        <div className="table_header">
          <Typography className="header">{heading}</Typography>
        </div> :
        <></>
      }
      {!isLoading?(<TableContainer
        sx={{
          border: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: "6px",
          marginBottom: "2.5rem",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <StyledTableHead>
            <TableRow className="header_row">
              {((platform === 'instagram'&& !isDashboard) ? instaColumnHeader :isTailwind?tailwidnHeader:(platform === 'instagram'&& isDashboard)?instaColumnHeaderDashboard:columnHeader).map((val, index) => {
                 if (val.label==="Verification") {
                  if(isDashboard){
                    return (
                      <TableCell
                        className="header"
                        key={index}
                        align={val.align}
                      >
                        {val.label}
                      </TableCell>
                    );
                  }else{
                    return null
                  }
                  
                } 
                if (val.label==="Followers") {
                  if(isDashboard){
                    return (
                      <TableCell
                        className="header"
                        key={index}
                        align={val.align}
                      >
                        {val.label}
                      </TableCell>
                    );
                  }else{
                    return null
                  }
                } 
                if (val.label !== "Party") {
                  if (reportType > 6 && val.report === "specific") {
                    return (
                      <TableCell
                        className="header"
                        key={index}
                        align={val.align}
                      >
                        No. of Posts in Top30
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        className="header"
                        key={index}
                        align={val.align}
                      >
                        {val.label}
                      </TableCell>
                    );
                  }
                }else if (val.label === "Party" && partyRequired == "required") {
                  return (
                    <TableCell
                      className="header"
                      key={index}
                      align={val.align}
                    >
                      {val.label}
                    </TableCell>
                  );
                }
              })}
              {start_date !== end_date ?
                <TableCell
                  className="header"
                  align="center"
                >
                  Days Won
                </TableCell> :
                <></>
              }
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {val.length > 0 && val.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className="body_row"
              >
                <StyledTableCell component="th" scope="row">
                <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                  <Typography className="table_data_style">
                    {row.position}
                  </Typography>
                  {row.previous_rank > 0 &&isDashboard && (
                          <>
                            {
                              row.previous_rank - row.position > 0 ? (
                                <>
                                  <GreenTriangle
                                    style={{ width: "12px", height: "12px" }}
                                  />
                                  <Typography
                                    sx={{ fontSize: "12px" }}
                                  >{`${Math.abs(
                                    row.previous_rank - row.position
                                  )}`}</Typography>
                                </>
                              ) : row.previous_rank - row.position < 0 ? (
                                <>
                                  <RedTriangle
                                    style={{ width: "12px", height: "12px" }}
                                  />
                                  <Typography
                                    sx={{ fontSize: "12px" }}
                                  >{`${Math.abs(
                                    row.previous_rank - row.position
                                  )}`}</Typography>
                                </>
                              ) : null /* or some other icon for no change */
                            }
                          </>
                        )}
                        </Box>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography className="table_data_style">
                    <a 
                      href={`https://${platform}.com/${row.page__page_id}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="link_style"
                    >
                      {row.page__page_name}
                    </a>
                  </Typography>
                </StyledTableCell>
                {partyRequired === "required" ?
                  <StyledTableCell align="left">
                    <Typography className="table_data_style">
                      {row.page__political_party__name}
                    </Typography>
                  </StyledTableCell> :
                  <></>
                }
                <StyledTableCell align="center">
                  <Typography
                    className="table_data_num"
                  >
                    <span
                      className="numbers_style"
                      style={{ background: start_date !== end_date ? `${randomColour("score", row.score)}` : "" }}
                    >
                      {row.score.toLocaleString()}
                    </span>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    className="table_data_num"
                  >
                    <span
                      className="numbers_style"
                      style={{ background: start_date !== end_date ? `${randomColour("total_posts", row.total_posts)}` : "" }}
                    >
                      {row.total_posts.toLocaleString()}
                    </span>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    className="table_data_num"
                  >
                    <span
                      className="numbers_style"
                      style={{ background: start_date !== end_date ? `${randomColour("total_shares", row.total_shares)}` : "" }}
                    >
                      {row.total_shares.toLocaleString()}
                    </span>
                  </Typography>
                </StyledTableCell>
                {isTailwind ? (
                    <>
                      <StyledTableCell align="center">
                        <Typography className="table_data_num">
                          <span
                            className="numbers_style"
                            style={{ background: start_date !== end_date ? `${randomColour("total_engagement", row.total_engagement)}` : "" }}
                          >
                            {row.total_engagement.toLocaleString()}
                          </span>
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography className="table_data_num">
                          <span
                            className="numbers_style"
                            style={{ background: start_date !== end_date ? `${randomColour("num_posts_in_top", row.num_posts_in_top)}` : "" }}
                          >
                            {row.num_posts_in_top.toLocaleString()}
                          </span>
                        </Typography>
                      </StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell align="center">
                        <Typography className="table_data_num">
                          <span
                            className="numbers_style"
                            style={{ background: start_date !== end_date ? `${randomColour("num_posts_in_top", row.num_posts_in_top)}` : "" }}
                          >
                            {row.num_posts_in_top.toLocaleString()}
                          </span>
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography className="table_data_num">
                          <span
                            className="numbers_style"
                            style={{ background: start_date !== end_date ? `${randomColour("median_shares", row.median_shares)}` : "" }}
                          >
                            {row.median_shares.toLocaleString()}
                          </span>
                        </Typography>
                      </StyledTableCell>
                    </>
                  )}

                {/* <StyledTableCell align="center">
                  <Typography
                    className="table_data_num"
                  >
                    <span
                      className="numbers_style"
                      style={{ background: start_date !== end_date ? `${randomColour("num_posts_in_top", row.num_posts_in_top)}` : "" }}
                    >
                      {row.num_posts_in_top.toLocaleString()}
                    </span>
                  </Typography>
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  <Typography
                    className="table_data_num"
                  >
                    <span
                      className="numbers_style"
                      style={{ background: start_date !== end_date ? `${randomColour("max_shares", row.max_shares)}` : "" }}
                    >
                      {row.max_shares.toLocaleString()}
                    </span>
                  </Typography>
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <Typography
                    className="table_data_num"
                  >
                    <span
                      className="numbers_style"
                      style={{ background: start_date !== end_date ? `${randomColour("median_shares", row.median_shares)}` : "" }}
                    >
                      {row.median_shares.toLocaleString()}
                    </span>
                  </Typography>
                </StyledTableCell> */}
                {isDashboard &&  platform !== 'instagram'&&(
                    <StyledTableCell align="center">
                      <Typography className="table_data_num">
                        <span className="numbers_style">
                          {row.verification_status === true ? 'True' : 'False'}
                        </span>
                      </Typography>
                    </StyledTableCell>
                  )}
                  {isDashboard &&platform !== 'instagram'&& (
                    <StyledTableCell align="center">
                      <Typography className="table_data_num">
                        <span
                          className="numbers_style"
                          style={{ background:
                            (start_date !== end_date && row?.followers_count)
                              ? randomColour(
                                  "followers_count",
                                  row.followers_count
                                )
                              : "", }}
                        >
                          {row.followers_count?row.followers_count.toLocaleString():0}
                        </span>
                      </Typography>
                    </StyledTableCell>
                  )}
                {start_date !== end_date ?
                  <StyledTableCell align="center">
                    <Typography
                      className="table_data_num"
                    >
                      <span
                        className="numbers_style"
                        style={{ background: start_date !== end_date ? `${randomColour("no_of_days_won", row.no_of_days_won)}` : "" }}
                      >
                        {row.no_of_days_won.toLocaleString()}
                      </span>
                    </Typography>
                  </StyledTableCell> :
                  <></>
                }
                {/* <StyledTableCell align="center"></StyledTableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>): (<Loader diff={23} /> )}
    </Container>
  );
};

export default SimpleTable;