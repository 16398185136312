import React, { useEffect, useState } from "react";
import { Bar, Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables, ChartDataLabels);

const MixedChart = ({ data, customColor, indexedBy,tabValue }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (data && data['medianSharesData'].length) {
      const labels = data['medianSharesData'].map((item) => item[indexedBy]);
      const barData = data['totalSharesData'].filter((item) => item.total_posts !== 0).map((item) => item.total_posts);
      const barDataset = {
        label: (tabValue==="facebook"||tabValue === "instagram")?"Total Posts":"Total Videos",
        data: barData,
        backgroundColor: customColor[0],
        order: 1,
        yAxisID: 'y',
        datalabels: {
          anchor: 'end',
          align: 'start',
          offset: 5, 
        },
      };
      const lineData = data['medianSharesData'].map((item) => item.median_shares);
      const lineDataset = {
        label: tabValue==="facebook"?"Median Shares":tabValue==="instagram"?"Median Likes":"Median Views",
        data:lineData,
        borderColor: "#CD9400",
        backgroundColor: "#CD9400",
        type: "line",
        order: 0,
        fill: false,
        yAxisID: 'y1',
        datalabels: {
          anchor: 'end',
          align: 'start',
          offset: -20, 
        },
      };

      setChartData({
        labels,
        datasets: [barDataset, lineDataset],
      });
    } else {
      setChartData({
        labels: [],
        datasets: [],
      });
    }
  }, [data, customColor, indexedBy]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20, // Add padding to create space below the legend
      },
    },
    scales: {
      x: {
        ticks: {
          labelAngle: 0,
          font: {
            size: 10, 
          },
          callback: function (value) {
            const lbl = this.getLabelForValue(value );
            if (typeof lbl === 'string' && lbl.length > 28) {
                return `${lbl.substring(0, 28)}...`;
            }
            return lbl;
        },
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text:(tabValue==="facebook"|| tabValue ==="instagram")?'Total Posts':"Total Videos",
        },
        grid: {
          drawOnChartArea: false, 
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false, 
        },
        title: {
          display: true,
          text:tabValue==="facebook"?'Median Shares':tabValue==="instagram"?"Median Likes":'Median Views',
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
        
      },
      legend: {
        position: 'top', // This sets the legend position to the bottom
      },
      
    },
    barThickness: 40,
  };

  return(<div style={{ width: '100%' ,height:'400px'}}>
    <Bar data={chartData} options={options} />
  </div>);
};

export default MixedChart;
