import React, { useState, useEffect, useContext } from "react";

//Nivo
import { ResponsiveLine } from "@nivo/line";

//MUi
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  Typography,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styled from "@emotion/styled";

//import
import {
  format,
  parseISO,
  isValid,
  differenceInDays,
  isSameMonth,
  isSameYear,

} from "date-fns";

//contexts
import { AlertContext } from "contexts/AlertContext";

const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
  marginRight: "8px",
  padding: "5px",
  "& .editIcon": {
    fontSize: "1.4rem",
    color: disabled ? "#e6e6e6" : "#FF5252",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
  "& .delIcon": {
    fontSize: "2rem",
    background: "#EFFFFD",
    color: disabled ? "#e6e6e6" : "#FF5252",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.5rem",
    },
  },
}));
const CustomTooltip = ({ point ,tabValue}) => {
  return (
    <div
      style={{
        background: "#333",
        color: "#e0e0e0",
        padding: "8px",
        border: "none",
        borderRadius: "4px",
        boxShadow: "0 3px 8px rgba(0, 0, 0, 0.3)",
        fontSize: "10px",
      }}
    >
      <div style={{ marginBottom: "8px", fontWeight: "bold", color: "#fff" }}>
        {point.serieId}
      </div>
      <div style={{ display: "flex", gap: 2, marginBottom: "4px" }}>
        <span style={{ color: "#bbb" }}>{tabValue==="facebook"?"Total Posts":tabValue==="instagram"?"Total Posts":"Total Videos"}</span>
        <span style={{ fontWeight: "bold" }}>{point.data.y}</span>
      </div>
      <div style={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
        <span style={{ color: "#bbb" }}>Date Range:</span>
        <span>{point.data.fullDate}</span>
      </div>
    </div>
  );
};
const LineChartComponent = ({ data,tabValue }) => {
  const [selectedPages, setSelectedPages] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [firstTime, setFirstTime] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [weekMap, setWeekMap] = useState({});
  const { showAlert } = useContext(AlertContext);
  const pageOptions = [...new Set(data.map((item) => item.page__page_id))].map(
    (id) => ({
      value: id,
      label: data.find((item) => item.page__page_id === id).page__page_name,
    })
  );

  const createWeekMap = (dateRanges) => {
    const sortedRanges = dateRanges.sort(
      (a, b) => new Date(a.split("|")[0]) - new Date(b.split("|")[0])
    );
    let weekCounter = 1;
    const map = {};

    sortedRanges.forEach((range) => {
      const [start, end] = range.split("|");
      const startDate = parseISO(start);
      const endDate = parseISO(end);

      if (
        differenceInDays(endDate, startDate) >= 0 &&
        differenceInDays(endDate, startDate) <= 6
      ) {
        map[range] = weekCounter;
        weekCounter++;
      }
    });

    return map;
  };

  const formatDateLabel = (start, end, weekMap, tabValue) => {
    const startDate = parseISO(start);
    const endDate = parseISO(end);

    if (!isValid(startDate) || !isValid(endDate)) {
      if (start.length === 7) {
        return format(parseISO(start + "-01"), "MMMM yyyy");
      }
      return start;
    }

    const daysDifference = differenceInDays(endDate, startDate);
    const dateRange = `${start}|${end}`;

    // Check if it's part of a week series
    if (differenceInDays>7 &&weekMap[dateRange]) {
      return `Week ${weekMap[dateRange]}`;
    }

    // For same day or consecutive days in the same month
    if (isSameMonth(startDate, endDate)) {
      if (daysDifference === 0) {
        return format(startDate, "MMM d");
      } else {
        return `${format(startDate, "MMM d")} - ${format(endDate, "d")}`;
      }
    }

    // For ranges spanning different months in the same year
    if (isSameYear(startDate, endDate)) {
      return `${format(startDate, "MMM d")} - ${format(endDate, "MMM d")}`;
    }

    // For ranges spanning different years
    return `${format(startDate, "MMM d, yyyy")} - ${format(endDate, "MMM d, yyyy")}`;
  };

  useEffect(() => {
    if (selectedPages.length === 0 && pageOptions.length > 0 && firstTime) {
      setSelectedPages(pageOptions.slice(0, 5).map((option) => option.value));
    }
  }, [pageOptions]);

  useEffect(() => {
    const filteredData = data.filter((item) =>
      selectedPages.includes(item.page__page_id)
    );
    const uniqueDateRanges = [
      ...new Set(
        filteredData.map((item) => `${item.start_date}|${item.end_date}`)
      ),
    ].sort();

    const newWeekMap = createWeekMap(uniqueDateRanges);
    setWeekMap(newWeekMap);

    const itemsPerPage = 7;
    setTotalPages(Math.ceil(uniqueDateRanges.length / itemsPerPage));

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedDateRanges = uniqueDateRanges.slice(startIndex, endIndex);

    const nivoData = selectedPages.map((pageId) => {
      const pageData = filteredData.filter(
        (item) => item.page__page_id === pageId
      );
      const pageName = pageData[0]?.page__page_name || "";
      return {
        id: pageName,
        data: paginatedDateRanges.map((dateRange) => {
          const [start, end] = dateRange.split("|");
          const item = pageData.find(
            (d) => d.start_date === start && d.end_date === end
          );
          return {
            x: formatDateLabel(start, end, newWeekMap,tabValue),
            y: item ? item.total_posts : 0,
            fullDate: `${format(parseISO(start), "MMM d, yyyy")} - ${format(
              parseISO(end),
              "MMM d, yyyy"
            )}`,
          };
        }),
      };
    });

    setChartData(nivoData);
  }, [selectedPages, currentPage, data]);
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  const handlePageSelect = (event) => {
    const value = event.target.value;

    // Prevent deselecting the last selected item
    if (value.length === 0) {
      showAlert(`At least 1 ${tabValue === "facebook" ?"Page":"Channel"} needs to be selected`, "error");
      return;
    }
    if (value.length <= 5) {
      setSelectedPages(value.slice(0, 5));
      setCurrentPage(0);
      setFirstTime(false);
    } else {
      showAlert(`Select Up to 5 ${tabValue === "facebook" ? "Pages" : "Channels"}`, "error");
    }
    

   
  };

  const handlePagination = (direction) => {
    setCurrentPage((prevPage) => {
      if (direction === "next" && prevPage < totalPages - 1)
        return prevPage + 1;
      if (direction === "prev" && prevPage > 0) return prevPage - 1;
      return prevPage;
    });
  };

  return (
    <Box >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h6"
          mr={2}
          sx={{ color: "#002164", fontSize: "1rem" }}
        >
          {(tabValue==="facebook"||tabValue === "instagram")?"Number of Posts":"Number of Videos"}
        </Typography>{" "}
        <Box display="flex" justifyContent="end" alignItems="center">
        <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={`Select Up to 10 ${tabValue==="facebook"?"Pages":tabValue==="instagram"?"Accounts":"Channels"}`}
          open={tooltipOpen}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <FormControl
            variant="outlined"
            sx={{
              width: "250px", // Adjust this value as needed
            }}
          >
            <InputLabel
              variant="outlined"
              id="page-select-label"
              sx={{
                fontSize: "12px",
                backgroundColor: "white",
                padding: "0 4px",
              }}
            >
              {tabValue=="facebook"?"Select pages":tabValue==="instagram"?"Select Accounts":"Select channels"}
            </InputLabel>
            <Select
              labelId="page-select-label"
              multiple
              variant="outlined"
              value={selectedPages}
              onChange={handlePageSelect}
              renderValue={(selected) => {
                const selectedLabels = selected
                  .map(
                    (id) =>
                      pageOptions.find((option) => option.value === id)?.label
                  )
                  .filter(Boolean);
                return selectedLabels.length > 0
                  ? `${selectedLabels.length} selected`
                  : "";
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 224,
                    maxWidth: 240,
                    paddingTop: 0,
                    "& .MuiMenuItem-root": {
                      fontSize: "12px",
                    },
                    "& .MuiTypography-root": {
                      fontSize: "12px",
                    },
                    "& .MuiCheckbox-root": {
                      padding: "4px",
                    },
                    "& .MuiMenu-list": { paddingTop: "0px" },
                  },
                },
              }}
              sx={{
                fontSize: "12px",
                width: "100%", // Make sure Select takes full width of FormControl
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                },
                "& .MuiSelect-select": {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                },
              }}
            >
              {pageOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ fontSize: "12px" }}
                >
                  <Checkbox
                    checked={selectedPages.includes(option.value)}
                    disabled={
                      selectedPages.length >= 6 &&
                      !selectedPages.includes(option.value)
                    }
                    sx={{ fontSize: "12px" }}
                  />
                  <ListItemText
                    primary={option.label}
                    sx={{ fontSize: "12px" }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Tooltip>
      </ClickAwayListener>
        </Box>
      </Box>

      {chartData.length > 0 && (
        <>
          <Box sx={{ height: 400 }}>
            <ResponsiveLine
              curve="cardinal"
              data={chartData}
              margin={{ top: 50, right: 30, bottom: 120, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              enableGridX={false}
              enableGridY={false}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                legend: "Date Range",
                legendOffset: 40,
                legendPosition: "middle",
                legendStyle: {
                  fontWeight: "bold",
                },
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: tabValue==="facebook"?"Total Posts":tabValue==="instagram"?"Total Posts":"Total Videos",
                legendOffset: -40,
                legendPosition: "middle",
                legendStyle: {
                  fontWeight: "bold",
                  fontSize: 14,
                },
              }}
              pointSize={8}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  // justify: false,
                  itemDirection: "left-to-right",
                  itemsSpacing: 10,
                  itemWidth: 160,
                  itemHeight: 10,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  translateY: 120, // Adjust this value to fine-tune vertical position
                  translateX: -100,
                },
              ]}
              theme={{
                axis: {
                  legend: {
                    text: {
                      fontWeight: "bold",
                      fontSize: 12, // Adjust as needed
                    },
                  },
                  domain: {
                    line: {
                      stroke: "#777777",
                      strokeWidth: 1,
                    },
                  },
                },
              }}
              tooltip={({ point }) => <CustomTooltip point={point} tabValue={tabValue} />}
              colors={["#0C5F79", "#FC9D02", "#FE5F55"]}
            />
          </Box>

          {!(currentPage === 0 || currentPage === totalPages - 1) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <StyledIconButton
                onClick={() => handlePagination("prev")}
                disabled={currentPage === 0}
              >
                <ChevronLeftIcon className="delIcon" />
              </StyledIconButton>
              <StyledIconButton
                onClick={() => handlePagination("next")}
                disabled={currentPage === totalPages - 1}
              >
                <ChevronRightIcon className="delIcon" />
              </StyledIconButton>
            </Box>
          )}
        </>
      )}
      {selectedPages.length === 0 && (
        <div
          style={{
            textAlign: "center",
            marginTop: "2rem",
            color: "red",
            fontWeight: "bold",
            fontSize: "12px",
          }}
        >
          Please Select Pages From List
        </div>
      )}
    </Box>
  );
};

export default LineChartComponent;
